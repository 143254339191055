<template>
  <div>
    <div class="mb-10">
      <router-link :to="{ name: 'auth.login' }">
        <small class="muted--text">Back to Login</small>
      </router-link>
      <h1 title="Login" class="mb-0">Reset Password</h1>
      <p class="muted--text">Please enter your new password to continue</p>

      <v-alert dismissible v-if="undefined != err.message" type="error" class="mb-8" outlined dense>
        {{ err.message }}
      </v-alert>
    </div>
    <v-form
      v-if="!isShowSuccess"
      @submit.prevent="validate"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="mb-3">
        <v-text-field
          v-model="password"
          :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="isShowPassword ? 'text' : 'password'"
          name="input-10-1"
          label="New Password"
          :rules="passwordRules"
          counter
          @click:append="isShowPassword = !isShowPassword"
        ></v-text-field>
      </div>
      <div class="mb-3">
        <v-text-field
          v-model="confirm"
          :append-icon="isShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          :type="isShowConfirm ? 'text' : 'password'"
          name="input-10-1"
          label="Confirm Password"
          :rules="confirmPasswordRules"
          counter
          @click:append="isShowConfirm = !isShowConfirm"
        ></v-text-field>
      </div>
      <div class="text-center">
        <v-btn
          block
          x-large
          color="primary"
          exact
          :loading="loadingSave"
          :disabled="loadingSave"
          @click="validate"
        >
          <span v-text="'Continue'"></span>
        </v-btn>
      </div>
    </v-form>
    <div v-else>
      <v-alert dismissible type="success" class="mb-8" outlined dense>
        Password has been successfully changed.
      </v-alert>
      <div class="text-center">
        <v-btn block x-large color="primary" exact :to="{ name: 'auth.login' }">
          <span v-text="'Back to Login'"></span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hondaPasswordRequirements,
  confirmPassword,
  minLength
} from '@/config/validations'
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isShowPassword: false,
      isShowConfirm: false,
      password: null,
      confirm: null,
      valid: true,
      loadingSave: false,
      isShowSuccess: false,
      passwordRules: [
        (value) => !!value || "Please type password.",
        (value) => minLength(value, 10),
        hondaPasswordRequirements
      ],
      confirmPasswordRules: [
        (value) => !!value || "Please type confirm password",
        (value) => confirmPassword(value, this.password)
      ],
    };
  },
  computed: {
    ...mapGetters({
      err: "auth/GET_ERR",
    }),
  },
  methods: {
    ...mapActions({
      actionResetPassword: "auth/actionResetPassword",
    }),

    async validate() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.loadingSave = true;
      await this.resetPassword();
    },

    async resetPassword() {
      await this.actionResetPassword({
        password: this.password,
        password_confirmation: this.confirm,
        token: this.$route.query.token,
        email: this.$route.query.email,
      });
      if (this.err.message == undefined) {
        this.$refs.form.reset();
        this.isShowSuccess = true;
      }
      this.loadingSave = false;
    },
  },
};
</script>

<style></style>
