export const hondaPasswordRequirements = (value) => 
    /**
     * Should contain at least one numeric character
     * Should contain at least one uppercase character
     * Should contain at least one lowercase character
     * Should contain at least one special character
     */
    // /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){10,}$/
    (!value || (value && /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){1,}$/.test(value))) ||
    "Should contain at least one numeric, uppercase, lowercase, and special character.";

export const confirmPassword = (value, reference) =>
    value === reference || 'The password confirmation does not match.';

export const minLength = (value, length = 10) => (!value || value.length >= length) || `Minimum ${length} characters`;